<template>
  <svg :class="svgClass" v-on="$listeners">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    //通过iconClass 获取svg文件名
    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon {
  /* 預設顏色 */
  /* 個別更改 style="fill : black" */
  height: 1.4rem;
  width: 1.4rem;
  overflow: hidden;
}

.white {
  fill: white;
}

.white_stroke {
  stroke: white;
}

.black {
  fill: black;
}
.primary {
  fill: var(--primary);
}

.success {
  fill: var(--success);
}

.success_stroke {
  stroke: var(--success);
}

.danger {
  fill: var(--danger);
}

.warning {
  fill: var(--warning);
}

.gray-divide {
  fill: var(--gray-divide);
}
.gray-border {
  fill: var(--gray-border);
}

.gray-border_stroke {
  stroke: var(--gray-border);
}

.gray-dark {
  fill: var(--gray-dark);
}

.gray-text-light {
  fill: var(--gray-text-light);
}

.gray-text-light_stroke {
  stroke: var(--gray-text-light);
}

.gray-background {
  fill: var(--gray-background);
}

.gray-lightest {
  fill: var(--gray-lightest);
}

.xxs {
  height: 0.9rem;
  width: 0.9rem;
}

.xs {
  height: 1.1rem;
  width: 1.1rem;
}

.sm {
  height: 1.3rem;
  width: 1.3rem;
}

.md {
  height: 1.5rem;
  width: 1.5rem;
}

.lg {
  height: 1.6rem;
  width: 1.6rem;
}

.xl {
  height: 1.7rem;
  width: 1.7rem;
}
.xxl {
  height: 2rem;
  width: 2rem;
}
</style>
