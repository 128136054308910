// import Cookies from 'js-cookie'

export default {
  // modules 內只有 state 是區域，其他會混進全域。所以需加上 namespaced
  namespaced: true,

  state: {
    // token: Cookies.get('token') || '',
    token: localStorage.getItem('token') || '',
    uname: localStorage.getItem('uname') || '',
    salesman_status: localStorage.getItem('salesman_status') || -1,
    funcs: localStorage.getItem('funcs') || JSON.stringify([]),
  },

  mutations: {
    UPDATE_COOKIE(state, payload) {
      // console.log(payload)
      state.token = payload['token']
      state.uname = payload['uname']
      state.salesman_status = payload['salesman_status']
      state.funcs = JSON.stringify(payload['funcs'])
      // Cookies.set('token', payload, { expires: 7 })
      localStorage.setItem('token', payload['token'])
      localStorage.setItem('uname', payload['uname'])
      localStorage.setItem('salesman_status', payload['salesman_status'])
      localStorage.setItem('funcs', JSON.stringify(payload['funcs']))
    },
    LOG_OUT(state) {
      // 登出 清除所有值
      // Cookies.remove('token')
      state.token = ''
      state.uname = ''
      state.salesman_status = -1
      state.funcs = JSON.stringify([])
      localStorage.clear()
    },
  },

  actions: {},
  getters: {},
}
