<template>
  <div id="app">
    <router-view />
    <overlay></overlay>
  </div>
</template>

<script>
import overlay from '@/components/overlay.vue'
export default {
  components: {
    overlay,
  },
  created() {
    // window.addEventListener('scroll', () => {
    //   let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    //   let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
    //   let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
    //   if (scrollTop === 0) {
    //     console.log('到頂')
    //   }
    //   if (scrollTop + clientHeight >= scrollHeight) {
    //     console.log('到底')
    //   }
    // })
  },
  methods: {},
}
</script>

<style lang="scss"></style>
