<template>
  <div class="my-textarea__outer relative w-full">
    <textarea
      ref="textarea"
      @focus="inputFocus"
      @blur="inputFocus"
      :disabled="disabled"
      :value="value"
      :placeholder="placeholder"
      :maxlength="maxlength"
      @input="handleInput"
      @keydown.delete="keydownDelete"
      type="text"
      class="my-textarea__inner"
      :class="[isFocus ? 'pt-4' : '', { disabled: disabled }]"
      :style="{ minHeight: height }"
    />
    <!-- <label
      class="my-textarea__label absolute left-4 cursor-text transform transition-all"
      :class="[isFocus ? 'text-xs top-1' : 'top-2', { disabled: disabled }]"
    >
      <slot></slot>
    </label> -->
  </div>
</template>

<script>
export default {
  name: 'myTextarea',
  data() {
    return {
      isFocus: false,
    }
  },
  props: {
    // 判斷是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 判斷頁面初載入時是否 autofocus
    autofocus: {
      type: Boolean,
      default: false,
    },
    // 雙向綁定v-model
    value: {
      type: String | Number,
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '200px',
    },
    placeholder: {
      type: String,
    },
    maxlength: {
      type: String,
    },
  },
  methods: {
    // focus時改變樣式
    inputFocus() {
      if (this.value) {
        this.isFocus = true
      } else {
        this.isFocus = !this.isFocus
      }
    },
    // 雙向綁定v-model
    handleInput(event) {
      this.$emit('input', event.target.value)

      // 自動撐開高度
      if (this.autoHeight) {
        let scrollHeight = this.$refs.textarea.scrollHeight + 2 + 'px'
        this.$refs.textarea.style.height = scrollHeight
      }
    },
    // 按刪除鍵時 高度減少
    keydownDelete() {
      if (parseInt(this.$refs.textarea.style.height, 10) >= 224) {
        let scrollHeight = this.$refs.textarea.scrollHeight + 2
        this.$refs.textarea.style.height = scrollHeight - 14 + 'px'
      }
    },
  },
}
</script>

<style scoped>
.my-textarea__inner {
  border: 1px solid var(--gray-border);
  border-radius: 12px;
  width: 100%;
  padding: 13px !important;
  outline: none;
  resize: none;
  min-height: 200px;
}
.my-textarea__inner.disabled {
  opacity: 0.6;
  background: white;
  border-color: rgba(236, 236, 236, 1);
}

.my-textarea__label.disabled {
  color: rgb(102, 102, 102);
}
</style>
