import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/admin',
    redirect: '/admin/home_setting',
    name: 'admin',
    component: () => import('../views/Admin.vue'),
    meta: {
      requiresAuth: true, // 需被驗證才能進入底下頁面
    },
    children: [
      {
        path: 'account',
        name: 'account',
        component: () => import('../views/Account.vue'),
        meta: {
          title: '帳號設定',
        },
      },
      // 首頁管理====================================================
      {
        path: 'platform_management',
        name: 'platform_management',
        redirect: 'home_setting',
      },
      {
        path: 'home_setting',
        name: 'home_setting',
        component: () => import('../views/homes/HomeSetting.vue'),
        meta: {
          title: '編輯首頁設定',
          sideMenuIndex: 0,
        },
      },
      // 服務項目--------------------
      {
        path: 'home_services',
        name: 'home_services',
        component: () => import('../views/rooms/HomeServices.vue'),
        meta: {
          title: '服務項目',
          sideMenuIndex: 0,
        },
      },
      {
        path: 'home_services/new',
        name: 'new-home_service',
        component: () => import('../views/rooms/HomeService.vue'),
        meta: {
          title: '新增服務項目',
          sideMenuIndex: 0,
        },
      },
      {
        path: 'home_services/:id',
        name: 'edit-home_service',
        component: () => import('../views/rooms/HomeService.vue'),
        meta: {
          title: '編輯服務項目',
          sideMenuIndex: 0,
        },
      },
      {
        path: 'home_services_rank',
        name: 'home_services_rank',
        component: () => import('../views/rooms/HomeServicesRank.vue'),
        meta: {
          title: '編輯服務項目排序',
          sideMenuIndex: 0,
        },
      },
      // 特色物件--------------------
      {
        path: 'room_features',
        name: 'room_features',
        component: () => import('../views/room_features/RoomFeatures.vue'),
        meta: {
          title: '特色物件',
          sideMenuIndex: 0,
        },
      },
      {
        path: 'room_features/:id',
        name: 'edit-room_feature',
        component: () => import('../views/room_features/RoomFeature.vue'),
        meta: {
          title: '編輯特色物件',
          sideMenuIndex: 0,
        },
      },
      {
        path: 'room_features_rank',
        name: 'room_features_rank',
        component: () => import('../views/room_features/RoomFeaturesRank.vue'),
        meta: {
          title: '編輯特色物件排序',
          sideMenuIndex: 0,
        },
      },
      // 影片專區--------------------
      {
        path: 'home_videos',
        name: 'home_videos',
        component: () => import('../views/rooms/HomeVideos.vue'),
        meta: {
          title: '影片專區',
          sideMenuIndex: 0,
        },
      },
      {
        path: 'home_videos/new',
        name: 'new-home_video',
        component: () => import('../views/rooms/HomeVideo.vue'),
        meta: {
          title: '新增影片專區',
          sideMenuIndex: 0,
        },
      },
      {
        path: 'home_videos/:id',
        name: 'edit-home_video',
        component: () => import('../views/rooms/HomeVideo.vue'),
        meta: {
          title: '編輯影片專區',
          sideMenuIndex: 0,
        },
      },
      // 關於我們=========================================================
      {
        path: 'about_management',
        name: 'about_management',
        redirect: 'founding_purpose',
      },
      {
        path: 'founding_purpose',
        name: 'founding_purpose',
        component: () => import('../views/homes/FoundingPurpose.vue'),
        meta: {
          title: '編輯創立目的',
          sideMenuIndex: 1,
        },
      },
      {
        path: 'team_introduction',
        name: 'team_introduction',
        component: () => import('../views/homes/TeamIntroduction.vue'),
        meta: {
          title: '編輯團隊介紹',
          sideMenuIndex: 1,
        },
      },
      {
        path: 'landlord',
        name: 'landlord',
        component: () => import('../views/homes/Landlord.vue'),
        meta: {
          title: '編輯什麼是二房東',
          sideMenuIndex: 1,
        },
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: () => import('../views/homes/Privacy.vue'),
        meta: {
          title: '編輯隱私權條款',
          sideMenuIndex: 1,
        },
      },
      // 物件管理====================================================
      {
        path: 'room_management',
        name: 'room_management',
        redirect: 'rooms',
      },
      {
        path: 'rooms',
        name: 'rooms',
        component: () => import('../views/rooms/Rooms.vue'),
        meta: {
          title: '物件列表',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rooms/new',
        name: 'new-room',
        component: () => import('../views/rooms/Room.vue'),
        meta: {
          title: '新增物件',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rooms/:id',
        name: 'edit-room',
        component: () => import('../views/rooms/Room.vue'),
        meta: {
          title: '編輯物件',
          sideMenuIndex: 2,
        },
      },
      // 房型樣式--------------------
      {
        path: 'rstyles',
        name: 'rstyles',
        component: () => import('../views/rooms/RStyles.vue'),
        meta: {
          title: '房型樣式',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rstyles/new',
        name: 'new-rstyle',
        component: () => import('../views/rooms/RStyle.vue'),
        meta: {
          title: '新增房型樣式',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rstyles/:id',
        name: 'edit-rstyle',
        component: () => import('../views/rooms/RStyle.vue'),
        meta: {
          title: '編輯房型樣式',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rstyles_rank',
        name: 'rstyles_rank',
        component: () => import('../views/rooms/RStylesRank.vue'),
        meta: {
          title: '編輯房型樣式排序',
          sideMenuIndex: 2,
        },
      },
      // 房間資訊--------------------
      {
        path: 'rdevices',
        name: 'rdevices',
        component: () => import('../views/rooms/RDevices.vue'),
        meta: {
          title: '房間資訊',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rdevices/new',
        name: 'new-rdevice',
        component: () => import('../views/rooms/RDevice.vue'),
        meta: {
          title: '新增房間資訊',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rdevices/:id',
        name: 'edit-rdevice',
        component: () => import('../views/rooms/RDevice.vue'),
        meta: {
          title: '編輯房間資訊',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rdevices_rank',
        name: 'rdevices_rank',
        component: () => import('../views/rooms/RDevicesRank.vue'),
        meta: {
          title: '編輯房間資訊排序',
          sideMenuIndex: 2,
        },
      },
      // 費用說明--------------------
      {
        path: 'rservices',
        name: 'rservices',
        component: () => import('../views/rooms/RServices.vue'),
        meta: {
          title: '費用說明',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rservices/new',
        name: 'new-rservice',
        component: () => import('../views/rooms/RService.vue'),
        meta: {
          title: '新增費用說明',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rservices/:id',
        name: 'edit-rservice',
        component: () => import('../views/rooms/RService.vue'),
        meta: {
          title: '編輯費用說明',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rservices_rank',
        name: 'rservices_rank',
        component: () => import('../views/rooms/RServicesRank.vue'),
        meta: {
          title: '編輯費用說明排序',
          sideMenuIndex: 2,
        },
      },
      // 其他需求--------------------
      {
        path: 'rneeds',
        name: 'rneeds',
        component: () => import('../views/rooms/RNeeds.vue'),
        meta: {
          title: '其他需求',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rneeds/new',
        name: 'new-rneed',
        component: () => import('../views/rooms/RNeed.vue'),
        meta: {
          title: '新增其他需求',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rneeds/:id',
        name: 'edit-rneed',
        component: () => import('../views/rooms/RNeed.vue'),
        meta: {
          title: '編輯其他需求',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rneeds_rank',
        name: 'rneeds_rank',
        component: () => import('../views/rooms/RNeedsRank.vue'),
        meta: {
          title: '編輯其他需求排序',
          sideMenuIndex: 2,
        },
      },
      // 型態資訊--------------------
      {
        path: 'rtypes',
        name: 'rtypes',
        component: () => import('../views/rooms/RTypes.vue'),
        meta: {
          title: '型態資訊',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rtypes/new',
        name: 'new-rtype',
        component: () => import('../views/rooms/RType.vue'),
        meta: {
          title: '新增型態資訊',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rtypes/:id',
        name: 'edit-rtype',
        component: () => import('../views/rooms/RType.vue'),
        meta: {
          title: '編輯型態資訊',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rtypes_rank',
        name: 'rtypes_rank',
        component: () => import('../views/rooms/RTypesRank.vue'),
        meta: {
          title: '編輯型態資訊排序',
          sideMenuIndex: 2,
        },
      },
      // 標籤資訊--------------------
      {
        path: 'rtags',
        name: 'rtags',
        component: () => import('../views/rooms/RTags.vue'),
        meta: {
          title: '標籤資訊',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rtags/new',
        name: 'new-rtag',
        component: () => import('../views/rooms/RTag.vue'),
        meta: {
          title: '新增標籤資訊',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rtags/:id',
        name: 'edit-rtag',
        component: () => import('../views/rooms/RTag.vue'),
        meta: {
          title: '編輯標籤資訊',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'rtags_rank',
        name: 'rtags_rank',
        component: () => import('../views/rooms/RTagsRank.vue'),
        meta: {
          title: '編輯標籤資訊排序',
          sideMenuIndex: 2,
        },
      },
      // QA管理====================================================
      {
        path: 'qa_management',
        name: 'qa_management',
        redirect: 'qas',
      },
      {
        path: 'qas',
        name: 'qas',
        component: () => import('../views/qas/Qas.vue'),
        meta: {
          title: 'GG Home列表',
          sideMenuIndex: 3,
        },
      },
      {
        path: 'qas/new',
        name: 'new-qa',
        component: () => import('../views/qas/Qa.vue'),
        meta: {
          title: '新增GG Home資料',
          sideMenuIndex: 3,
        },
      },
      {
        path: 'qas/:id',
        name: 'edit-qa',
        component: () => import('../views/qas/Qa.vue'),
        meta: {
          title: '編輯GG Home資料',
          sideMenuIndex: 3,
        },
      },
      {
        path: 'qas_rank',
        name: 'qas_rank',
        component: () => import('../views/qas/QasRank.vue'),
        meta: {
          title: '編輯GG Home排序',
          sideMenuIndex: 3,
        },
      },
      // 報表管理====================================================
      {
        path: 'report_management',
        name: 'report_management',
        redirect: 'page_view',
      },
      {
        path: 'page_view',
        name: 'page_view',
        component: () => import('../views/reports/PageView.vue'),
        meta: {
          title: '瀏覽數',
          sideMenuIndex: 4,
        },
      },
      {
        path: 'sales_report',
        name: 'sales_report',
        component: () => import('../views/reports/SalesReport.vue'),
        meta: {
          title: '業務報表',
          sideMenuIndex: 4,
        },
      },
      // 權限管理====================================================
      {
        path: 'role_management',
        name: 'role_management',
        redirect: 'roles',
      },
      {
        path: 'roles',
        name: 'roles',
        component: () => import('../views/roles/Roles.vue'),
        meta: {
          title: '部門列表',
          sideMenuIndex: 5,
        },
      },
      {
        path: 'roles/new',
        name: 'new-role',
        component: () => import('../views/roles/Role.vue'),
        meta: {
          title: '新增部門資料',
          sideMenuIndex: 5,
        },
      },
      {
        path: 'roles/:id',
        name: 'edit-role',
        component: () => import('../views/roles/Role.vue'),
        meta: {
          title: '編輯部門資料',
          sideMenuIndex: 5,
        },
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('../views/roles/Users.vue'),
        meta: {
          title: '員工列表',
          sideMenuIndex: 5,
        },
      },
      {
        path: 'users/new',
        name: 'new-user',
        component: () => import('../views/roles/User.vue'),
        meta: {
          title: '新增員工資料',
          sideMenuIndex: 5,
        },
      },
      {
        path: 'users/:id',
        name: 'edit-user',
        component: () => import('../views/roles/User.vue'),
        meta: {
          title: '編輯員工資料',
          sideMenuIndex: 5,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '登入',
    },
  },
  {
    path: '/404notfound',
    name: '404',
    component: () => import('../views/404NotFound.vue'),
    meta: {
      title: '404 Not Found_',
    },
  },
  {
    path: '*',
    redirect: '/404notfound',
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  // base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  },
})

// 路由跳轉前調用
router.beforeEach((to, from, next) => {
  // 若進到需驗證的頁面
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let token = store.state.login.token
    if (token) {
      // 1.3.1 讀取網站參數
      // Vue.prototype
      //   .$axios({
      //     url: 'front/overview/getWebParam.php',
      //     headers: {
      //       token,
      //     },
      //     kcval: true,
      //     data: {
      //       lang: 0,
      //     },
      //   })
      //   .then((res) => {
      //     if (res.data.Success) {
      //       // 儲存網站參數
      //       store.commit('getWebData/UPDATE_COOKIE', res.data.Data)
      //     } else {
      //       console.error(res.data.Message)
      //     }
      //   })
      next()
    } else {
      location.href = './#/login'
    }
  }

  // 若進到登入或註冊頁面
  if (to.name === 'login' || to.name === 'register') {
    // 若已經登入了，跳轉到admin
    let token = store.state.login.token
    if (token) {
      next({
        name: 'admin',
        replace: true,
      })
    } else {
      next()
    }
  }

  next()
})

// 路由跳轉後調用
// 不會有 next 參數，因此不會影響路由的跳轉。 但是多了一個 failure 參數用來表示路由跳轉失敗。
router.afterEach((to, from, failure) => {
  document.title = `${to.meta.title} | Admin `

  // if (!failure) { // 若路由跳轉成功
  //   sendToAnalytics(to.fullPath) // 追蹤 GA 等工具
  // } else {
  //   // fallback...
  // }
})

export default router
