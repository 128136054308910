import axios from 'axios'
import store from '@/store'
import Vue from 'vue' // 導入實例
// import aes from '@/assets/utils/aes'
// import App from '@/main' // 導入所有掛載在main中的實例

// 建立默認設定
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    'Content-Type': 'application/json',
    // sn: process.env.VUE_APP_SN,
  },
  // transformRequest: [
  //   function (data) {
  //     // let src = ''
  //     // for (let item in data) {
  //     //   src += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&'
  //     // }
  //     return data
  //   },
  // ],
})

// 請求攔截
instance.interceptors.request.use((config) => {
  Vue.prototype.$Progress.start() // 進度條開始
  config.headers.Authorization = `Bearer ${store.state.login.token}`
  if (config.kcval) {
    // config.data.kcval = Vue.prototype.$aes.encrypt(
    //   store.state.login.userInfo.enval1,
    //   store.state.login.userInfo.enval2,
    //   store.state.login.token
    // )
  }
  return config
})

// 響應攔截
instance.interceptors.response.use(
  (res) => {
    Vue.prototype.$Progress.finish() // 進度條結束
    return res
  },
  (error) => {
    // 錯誤時的訊息
    // 200正常,201建立
    // 400程式異常,401使用者未登入,403使用者權限不足,404無此頁面,405不允許此方法
    // 414 Request-URI 太長,422請求參數有誤或缺失
    // 500 伺服器的內部錯誤
    if(error.response.status == 401){
      Vue.prototype.$myVS.openNoti('請重新登入', '', 'danger', 10000)
      localStorage.clear()
      location.href = 'login'
    }else if(error.response.status == 403){
      Vue.prototype.$myVS.openNoti('權限不足', '', 'danger', 10000)
    }else if(error.response.status == 422){
      Vue.prototype.$myVS.openNoti(error.response.data.message, '', 'danger', 10000)
    }else if(error.response.status == 200){
      Vue.prototype.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 10000)
    }
  }
)

export default instance
