<template>
  <transition name="fade">
    <div v-if="isActive" @click="close" class="modal_overlay_off z-2000" :class="{ modal_overlay_on: isActive }"></div>
  </transition>
</template>

<script>
export default {
  name: 'overlay',
  data() {
    return {}
  },
  computed: {
    isActive() {
      return this.$store.state.overlay
    },
  },
  watch: {
    isActive(newValue) {
      newValue ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden')
    },
  },
  methods: {
    close() {
      this.$store.dispatch('closeOverlay')
    },
  },
}
</script>

<style scoped>
.modal_overlay_off {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0px);
  transition: all 0.3s;
  visibility: hidden;
  cursor: pointer;
}

.modal_overlay_on {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  visibility: visible;
}
</style>
